/* eslint-disable no-nested-ternary */
import {
  Box,
  Button,
  Divider,
  Grid2,
  Link,
  Paper,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import PropTypes from 'prop-types';
import Image from '@components/atoms/Image2';
import { FiMail, FiPhone } from 'react-icons/fi';
import { MediaElement } from '@plugins/next-cms-core';
import Hidden from '@components/atoms/Hidden';
import { useTranslation } from 'next-i18next';
import twemoji from 'twemoji';
import { get } from 'lodash';
import { trackEvent } from '@components/organisms/Tracking';
import { styled } from '@mui/material/styles';

const MediaItem = styled(MediaElement)(({
  theme,
  isHorizontal,
}) => (isHorizontal ? ({
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  [theme.breakpoints.up('md')]: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
}) : ({
  [theme.breakpoints.down('sm')]: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  [theme.breakpoints.up('md')]: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
})));

const StyledImage = styled(Image)(({
  theme,
  isHorizontal,
}) => (isHorizontal ? ({
  width: 'auto',
  height: 'auto',
  display: 'block',
  [theme.breakpoints.down('sm')]: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  [theme.breakpoints.up('md')]: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
}) : ({
  [theme.breakpoints.down('sm')]: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  [theme.breakpoints.up('md')]: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
})));

const EmojiWrapper = styled('span')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginRight: theme.spacing(0.5),
  '& img': {
    height: theme.spacing(2),
    width: theme.spacing(2),
    margin: '.05em .05em 0 .1em',
    verticalAlign: '-0.1em',
  },
}));

const BranchLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'none',
}));

export default function StaffMember(props) {
  const {
    employee,
    variant,
    inventoryCar,
    onShowRequestForm,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const isHorizontal = variant === 'horizontal';

  return (
    <Paper
      sx={{ height: '100%' }}
      variant="outlined"
    >
      <Grid2
        container
        sx={{
          display: isHorizontal ? 'flex' : 'block',
        }}
      >
        <Grid2
          size={{
            md: isHorizontal && !inventoryCar ? 6 : inventoryCar ? 3 : 12,
            sm: inventoryCar ? 3 : 12,
            xs: 12,
          }}
          sx={{
            paddingRight: !inventoryCar ? 0 : 2,
          }}
        >
          {get(employee?.attributes, 'image') && (
            <MediaItem
              alt={employee?.attributes.name}
              data={employee?.attributes.image}
              isFluid
              isHorizontal={isHorizontal}
            />
          )}
          {!get(employee?.attributes, 'image') && inventoryCar && (
            <StyledImage
              alt="Ihre online Verkaufsberater"
              height={1001}
              isHorizontal={isHorizontal}
              src="/images/people/online-sales-team-v2.jpg"
              style={{
                display: 'block',
                width: '100%',
                height: 'auto',
              }}
              width={1001}
            />
          )}
        </Grid2>
        <Grid2
          size={{
            md: isHorizontal && !inventoryCar ? 6 : inventoryCar ? 9 : 12,
            sm: inventoryCar ? 9 : 12,
            xs: 12,
          }}
          sx={{
            textAlign: !inventoryCar ? 'center' : '',
            padding: !inventoryCar ? 2 : 0,
          }}
        >
          {get(employee?.attributes, 'primary_branch.data.attributes.name') && (
            <Typography variant="caption">
              {t('components.molecules.StaffMember.location')}
              {' '}
              <BranchLink href={employee.attributes.primary_branch.data.attributes.pageUrl}>
                {employee.attributes.primary_branch.data.attributes.name}
              </BranchLink>
            </Typography>
          )}
          <Grid2
            container
            spacing={1}
            sx={{
              alignItems: 'center',
              justifyContent: !inventoryCar ? 'center' : '',
            }}
          >
            <Grid2 size={{ xs: 'auto' }}>
              <Typography component="h4" variant="subtitle2">
                {get(employee?.attributes, 'name', 'CSB Schimmel Automobile GmbH')}
              </Typography>
            </Grid2>
            {get(employee?.attributes, 'languages.data', [])?.length > 0 && (
              <Grid2 size={{ xs: 'auto' }}>
                <Grid2 container sx={{ alignItems: 'center' }}>
                  {employee.attributes.languages.data.map((language) => (
                    <Tooltip key={language.id} title={language.attributes.name}>
                      <EmojiWrapper
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: twemoji.parse(language.attributes.emoji, {
                            base: 'https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/',
                          }),
                        }}
                      />
                    </Tooltip>
                  ))}
                </Grid2>
              </Grid2>
            )}
          </Grid2>
          {(get(employee?.attributes, 'position') || inventoryCar) && (
            <Typography component="h4" variant="body2">
              {get(employee?.attributes, 'position', 'Hyundai- & Mitsubishi-Vertragshändler')}
            </Typography>
          )}
          {inventoryCar && (
            <Typography sx={{ my: 1 }} variant="body2">
              {inventoryCar.attributes.branch.data.attributes.addressStreet}
              <br />
              {inventoryCar.attributes.branch.data.attributes.addressZipcode}
              {' '}
              {inventoryCar.attributes.branch.data.attributes.addressCity}
            </Typography>
          )}
          {!inventoryCar && (
            <Divider sx={{
              mt: 2,
              mb: 1,
            }}
            />
          )}
          <Box
            alignItems="center"
            display={inventoryCar ? 'flex' : 'inline-block'}
            sx={{ width: '100%' }}
          >
            {inventoryCar && (
              <Button
                color="primary"
                fullWidth
                onClick={onShowRequestForm}
                size="small"
                startIcon={<FiMail />}
                sx={{
                  mr: inventoryCar ? 1 : 0,
                  mt: 1,
                }}
                variant="outlined"
              >
                {t('components.molecules.StaffMember.requests')}
              </Button>
            )}
            {(get(employee?.attributes, 'phoneWhatsapp')) && (
              <Button
                component="a"
                fullWidth
                href={`https://wa.me/${get(employee.attributes, 'phoneWhatsapp')
                  .replace('+', '')}`}
                size="small"
                startIcon={<WhatsappIcon />}
                sx={{
                  mr: inventoryCar ? 1 : 0,
                  mt: 1,
                }}
                target="_blank"
              >
                <Hidden dir="down" size="sm">
                  {get(employee?.attributes, 'phoneWhatsapp')}
                </Hidden>
                <Hidden dir="up" size="md">
                  Whatsapp
                </Hidden>
              </Button>
            )}
            {(get(employee?.attributes, 'phone') || inventoryCar) && (
              <Button
                component="a"
                fullWidth
                href={`tel:${get(employee?.attributes, 'phone', inventoryCar?.attributes.branch.data.attributes.salesPhone)}`}
                onClick={() => {
                  trackEvent('Contact');
                  trackEvent('ContactCustom', {
                    channel: 'Phone',
                    origin: 'Employee',
                  });
                }}
                size="small"
                startIcon={<FiPhone />}
                sx={{
                  mr: inventoryCar ? 1 : 0,
                  mt: 1,
                  color: theme.palette.text.primary,
                }}
              >
                <Hidden dir="down" size="sm">
                  {get(employee?.attributes, 'phone', inventoryCar?.attributes.branch.data.attributes.salesPhone)}
                </Hidden>
                <Hidden dir="up" size="md">
                  {t('components.molecules.StaffMember.phone')}
                </Hidden>
              </Button>
            )}
            {(get(employee?.attributes, 'email') || inventoryCar) && (
              <Button
                component="a"
                fullWidth
                href={`mailto:${get(employee?.attributes, 'email', inventoryCar?.attributes.branch.data.attributes.primaryEmail)}`}
                onClick={() => {
                  trackEvent('Contact');
                  trackEvent('ContactCustom', {
                    channel: 'Email',
                    origin: 'Employee',
                  });
                }}
                size="small"
                startIcon={<FiMail />}
                sx={{
                  mr: inventoryCar ? 1 : 0,
                  mt: 1,
                  color: theme.palette.text.primary,
                }}
              >
                <Hidden dir="down" size="sm">
                  {t('components.molecules.StaffMember.writeEmail')}
                </Hidden>
                <Hidden dir="up" size="md">
                  {t('components.molecules.StaffMember.email')}
                </Hidden>
              </Button>
            )}
          </Box>
        </Grid2>
      </Grid2>
    </Paper>
  );
}

StaffMember.propTypes = {
  employee: PropTypes.object.isRequired,
  inventoryCar: PropTypes.object,
  onShowRequestForm: PropTypes.func,
  variant: PropTypes.oneOf(['horizontal', 'vertical']),
};

function WhatsappIcon() {
  return (
    <svg
      height="18"
      viewBox="0 0 425 425"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M361,62.38A207.17,207.17,0,0,0,213.47,1.22C98.52,1.22,5,94.77,4.92,209.76A208.16,208.16,0,0,0,32.76,314L3.17,422.09l110.56-29a208.28,208.28,0,0,0,99.66,25.38h.09c114.94,0,208.5-93.56,208.55-208.55A207.29,207.29,0,0,0,361,62.38ZM213.48,383.25h-.07a173.08,173.08,0,0,1-88.23-24.16l-6.33-3.76L53.24,372.54l17.51-64L66.63,302a173,173,0,0,1-26.5-92.25c0-95.57,77.8-173.33,173.42-173.33A173.37,173.37,0,0,1,386.82,209.91C386.78,305.49,309,383.25,213.48,383.25Zm95.08-129.82c-5.21-2.61-30.83-15.21-35.61-17s-8.25-2.6-11.72,2.61-13.46,17-16.5,20.44-6.08,3.91-11.29,1.3-22-8.11-41.91-25.87c-15.49-13.81-25.95-30.88-29-36.09s-.32-8,2.28-10.64c2.35-2.34,5.22-6.09,7.82-9.13s3.48-5.22,5.21-8.69.87-6.52-.43-9.13-11.73-28.26-16.07-38.7c-4.23-10.16-8.52-8.78-11.72-8.94-3-.15-6.52-.18-10-.18a19.15,19.15,0,0,0-13.9,6.52c-4.77,5.21-18.24,17.82-18.24,43.47s18.68,50.43,21.28,53.91,36.75,56.11,89,78.68a299.53,299.53,0,0,0,29.71,11c12.48,4,23.84,3.41,32.82,2.06,10-1.49,30.83-12.6,35.18-24.77s4.34-22.61,3-24.78S313.77,256,308.56,253.43Z"
          fill="#25d366"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
}
